import React, { useState } from "react";

import MarkdownViewer from "../../../components/merged/MarkdownViewer";
import { MediumTitle } from "../../../components/shared/FontCollection";
import "./Details.css";

const TABS = {
    INFORMATION: "Information",
    USAGE: "Usage"
}

export default function Details({ information, usage }) {
    const [activeIndex, setActiveIndex] = useState(TABS.INFORMATION);

    return <section className="details">
        <div className="details__content">
            <div className="details__tabs">
                {
                    Object.keys(TABS).map((tab, key) => {
                        return <div key={key} className="details__tab" style={activeIndex === TABS[tab] ? { borderBottom: "3px solid var(--dark-green)", fontWeight: "bold" } : null}>
                            <div id={tab} onClick={() => setActiveIndex(TABS[tab])}>
                                <MediumTitle>{TABS[tab]}</MediumTitle>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="details__content-viewer">
                {
                    <MarkdownViewer data={activeIndex === TABS.INFORMATION ? information : usage} />
                }
            </div>
        </div>
    </section>
}