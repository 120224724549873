import * as React from "react"
import { graphql } from "gatsby";

import Seo from "../components/shell/seo"
import LayoutWrapper from "./LayoutWrapper";
import ProductDetailContent from "../pageContents/ProductDetail/ProductDetailContent";
import { normalizeTranslations } from "../services/translationHelper.service";
import { getSeoData } from "../services/seo.service";

function ProductDetailWrapper(props) {
  return <LayoutWrapper pageContext={props.pageContext}>
    <Seo {...getSeoData(props, props.data.productsJson)} />
    <ProductDetailContent {...normalizeTranslations(props.pageContext.language, props.data.productsJson)} />
  </LayoutWrapper>
}

export default ProductDetailWrapper;

export const query = graphql`
  query($id: String) {
    productsJson(id: { eq: $id }) {
      categoryId
      id
      productsCode
      status
      link
      seoTranslations {
        description
        keywords
        language
        title
      }
      translations {
          information
          language
          price
          summary
          title
          usage
      }
      slideImage {
        id
        sort
        translations {
          description
          language
          title
        }
        image {
          alt
          src {
              childImageSharp {
                fixed {
                  src
                }
              }
          }
        }
      }
      mainPicture {
        alt
        src {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }
`