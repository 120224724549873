import React from "react";

import MarkdownViewer from "../../../components/merged/MarkdownViewer";
import { MediumTitle, TinyTitle } from "../../../components/shared/FontCollection";

import "./BasicInfo.css";

export default function BasicInfo({ title, subTitle, summary, price, link }) {
    return <div className="basic-info">
        <div className="basic-info__content">
            <div className="basic-info__title">
                <MediumTitle>{title}</MediumTitle>
            </div>
            <div className="basic-info__sub-title">
                <TinyTitle>{subTitle}</TinyTitle>
            </div>
            <div className="basic-info__description">
                <MarkdownViewer data={summary} />
            </div>
            <div className="basic-info__price">
                <p>{price}</p>
            </div>
            <div className="basic-info__visit-shop">
                <a target="_blank" rel="noreferrer" aria-label="Amazon's link" href={link}>Visit Amazon Shop</a>
            </div>
        </div>
    </div>
}