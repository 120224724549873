import React from "react";

import Details from "./sections/Details";
import Overview from "./sections/Overview";

export default function ProductDetailContent(props) {
    return <React.Fragment>
        <Overview {...props} />
        <Details usage={props.usage} information={props.information} />
    </React.Fragment>
}