import React from "react";

import Slideshow from "../components/Slideshow";
import BasicInfo from "../components/BasicInfo";
import "./Overview.css";

export default function Overview(props) {
    return <section className="overview">
        <div className="overview__content">
            <div className="overview__images">
                <Slideshow imageData={props.slideImage} />
            </div>
            <div className="overview__basic-info">
                <BasicInfo {...props} />
            </div>
        </div>
    </section>
}