import React, { useState } from "react";

import Image from "../../../components/merged/Image";
import "./Slideshow.css";

export default function Slideshow({ imageData }) {
    const [activeIndex, setActiveIndex] = useState(0);

    function onImageSelected(selectedIndex) {
        setActiveIndex(selectedIndex);
    }

    function onPrev() {
        if (activeIndex === 0) {
            setActiveIndex(imageData.length - 1);
        } else {
            setActiveIndex(prev => prev - 1);
        }
    }

    function onNext() {
        if (activeIndex === imageData.length - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(prev => prev + 1);
        }
    }

    return <div className="slideshow">
        <div className="slideshow__prev-button" onClick={onPrev}>〈</div>
        <div className="slideshow__next-button" onClick={onNext}>〉</div>
        <div className="slideshow__content">
            <div className="slideshow__thumbnails">
                {
                    imageData.map((dataItem, key) => {
                        return <div className="slideshow__thumbnails-item" style={activeIndex === key ? { border: "2px solid var(--dark-green)" } : null} key={key} onClick={() => onImageSelected(key)}>
                            <Image {...dataItem.image} className="slideshow__thumbnails-item-img" />
                        </div>
                    })
                }
            </div>
            <div className="slideshow__current-image">
                <Image {...imageData[activeIndex].image} className="slideshow__current-image-img" />
            </div>
        </div>
    </div>
}